html {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  height: calc(100%) !important;
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background: #000;
  -webkit-text-size-adjust: none
  /* touch-action: none; */
}
body {
  position:fixed;
  overflow:hidden;
  font-family: "Mulish", Courier, monospace;
  height: calc(100%) !important;
  width: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior-y: none;
  overscroll-behavior-x: none;
  /* touch-action: none; */
}
.swiper-container {
  height: calc(100%) !important;
}
.swiper-wrapper {
  height: calc(100%) !important;
}
.swiper-slide {
  height: calc(100%) !important;
}

progress {
  background-color: #333;
 
}

progress::-webkit-progress-bar {
  background-color: #333;
 
}
progress::-webkit-progress-value {
  background-color:#ADB6C4;
  
}

input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  cursor: pointer;
  height: 100%;
  width: 100%;
  text-align: center;
  line-height: 2rem;
}
input[type="radio"]:checked + label {
  /* border: 5px solid blue; */
  /* background-color: dodgerblue; */
}